<template>
  <CdeCard
    class="d-flex flex-column align-center justify-center text-center pa-8"
    max-width="95vw"
    width="560px">
    <v-card-title>
      <p class="text-h6 text-md-h5 font-weight-bold text-break">{{ t('new_price') }}</p>
      <p class="text-h6 text-md-h5 font-weight-bold text-break">
        {{ priceChangeLabel }}
      </p>
    </v-card-title>
    <v-card-text class="py-8">
      <p>{{ t('new_price_is') }}</p>
      <span class="text-h6 text-md-h5 text-primary font-weight-bold">
        {{
          formatCentToEuro(
            updatedOffers.find(container => container.directusContainerId === selectedContainer)
              ?.unitPrice.grossPrice as number,
          )
        }}
        &euro;
      </span>
      <p>
        ({{ t('without_vat') }}&nbsp;{{
          formatCentToEuro(
            updatedOffers.find(container => container.directusContainerId === selectedContainer)
              ?.unitPrice.netPrice as number,
          )
        }}
        &euro;)
      </p>
    </v-card-text>
    <v-card-action>
      <ButtonPrimary class="my-2" @click="confirmAction('accept')">
        {{ t('accept_change') }}
      </ButtonPrimary>
      <ButtonSecondary class="my-2" @click="confirmAction('decline')">
        {{ t('cancel_change') }}
      </ButtonSecondary>
    </v-card-action>
  </CdeCard>
</template>

<script setup lang="ts">
import type { ContainerOffer } from '~/types/types'
const { t } = useI18n()
const { formatCentToEuro } = useMoneyHelper()

const properties = defineProps<{
  updatedOffers: ContainerOffer[]
  selectedContainer: number
  reason: number // 1 = zip change, 2 = fraction change
}>()

const { reason } = toRefs(properties)

const priceChangeLabel = computed(() => {
  if (reason.value === 1) {
    return t('price_change_by_zip')
  } else {
    return t('price_change_by_fraction')
  }
})

const emit = defineEmits(['acceptNewPrice', 'declineNewPrice'])
const confirmAction = (action: string) => {
  if (action === 'accept') {
    emit('acceptNewPrice')
  } else {
    emit('declineNewPrice')
  }
}
</script>
<style lang="scss" scoped>
.v-btn.bg-primary.v-theme--cde2024Light.v-theme--cde2024Light {
  height: calc(var(--v-btn-height) + 0px) !important;
  font-weight: 700;
  @media (max-width: 600px) {
    height: calc(var(--v-btn-height) + 0px) !important;
    font-weight: 800;
  }
}
</style>
